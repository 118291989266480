import React from "react";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import SalaryHistoryTable from "./salary-history-table";
import { Divider } from "antd";
import PaymentTable from "./view-payment-table";

const PaymentView = ({
  viewModel,
  setViewModal,
  data,
  isLoading,
  isServiceProvider = false,
}) => {

  return (
    <div>
      <CustomAntdModal
        isOpenModal={viewModel}
        setIsOpenModal={setViewModal}
        title="View Employee Payment"
        isFooter={false}
      >
        {/* <Divider>
          {" "}
          <div className="font-extrabold">Payment History</div>{" "}
        </Divider>
        <PaymentTable data={data?.paymentHistory} /> */}

        {!isServiceProvider && (
          <>
            <Divider>
              {" "}
              <div className="font-extrabold">Salary History</div>{" "}
            </Divider>
            <SalaryHistoryTable
              data={data?.salaryHistory}
              isLoading={isLoading}
            />
          </>
        )}
      </CustomAntdModal>
    </div>
  );
};

export default PaymentView;
