import { apiHandler } from '(apis)/api-interface/api-handler';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { InternalPaymentsTable, ViewPaymentModal, PaymentModal } from '../payments/components/payment-modal';

export default function Page() {

    //get the type and id from the url
    const { type, id } = useParams();

    console.log("type: " + type);
    console.log("id: " + id);

    const [paymentHistory, setPaymentHistory] = useState([]);

    const GetPaymentHistory = async () => {
        try {
            const res = await apiHandler({ name: "getPaymentHistory", params: [type, id] })
            console.log("res: " + res.data.payments);
            if (res.data.isSuccess) {
                setPaymentHistory(res.data.payments);
            }
        } catch (error) {
            console.log("error: " + error);
        }
    }

    useEffect(() => {
        GetPaymentHistory();
    }, [type, id])

    const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [openViewPaymentModal, setOpenViewPaymentModal] = useState(false);
    const GetDate = (date: string) => {
        return new Date(date).toLocaleDateString();
    }

    return (
        <div>
            <h1>Payment History</h1>
            {type === "internal" && <InternalPaymentsTable
                internalPayments={paymentHistory}
                setOpenAddPaymentModal={setOpenAddPaymentModal}
                setModalType={setModalType}
                setSelectedPayment={setSelectedPayment}
                setOpenViewPaymentModal={setOpenViewPaymentModal}
                GetDate={GetDate}
                fetchInternalPayments={GetPaymentHistory}
                showEdit={false}
            />}
            <ViewPaymentModal
                ShowModel={openViewPaymentModal}
                setShowModel={setOpenViewPaymentModal}
                selectedPayment={selectedPayment}
            />

        </div>
    )
}
