import { apiHandler, apiRequest } from '(apis)/api-interface/api-handler';
import React, { useContext, useEffect, useState } from 'react'
import { Select } from 'antd';
import CustomDropDown from 'shared-components/ui/CustomDropDown';
import { categoryValues } from 'constants/constantvalues';
import { X } from '@mui/icons-material';
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import toast, { Toaster } from 'react-hot-toast';
import { UserContext } from 'state-management/user-context';
import { DeleteIcon, EditIcon, EyeIcon } from 'shared-components/ui/Icons';

export const PaymentModal = ({ ShowModel, setShowModel, isEditMode, selectedPayment, fetchInternalPayments, setModalType }: { ShowModel: boolean, setShowModel: (value: boolean) => void, isEditMode: boolean, selectedPayment: any, fetchInternalPayments: () => void, setModalType: (value: string) => void }) => {

    const [employees, setEmployees] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [departments, setDepartments] = useState([]);


    const { user } = useContext(UserContext);

    const GetEmployees = async () => {
        const res = await apiRequest("getAllEmployees");
        if (res.data?.isSuccess) {
            setEmployees(res.data?.employees);
        }
        const res2 = await apiRequest("getBankAccounts");
        if (res2.data?.isSuccess) {
            setBankAccounts(res2.data?.bankAccounts);
            setDepartments(res2.data?.departments);
        }
    }

    useEffect(() => {
        GetEmployees();
    }, [])


    interface BreakDownAmounts {
        category: string;
        amount: number;
        bonus: number;
        bankAccount: string;
        department: string;
    }

    const [formData, setFormData] = useState<{
        employeeId: string;
        type: string,
        breakdownAmounts: BreakDownAmounts[];
        paidByOurAccounts: string[];
        addedBy: string;
        modifiedBy: string;
        screenshot: string;
        createdAt: Date;
    }>({
        employeeId: "",
        type: "",
        breakdownAmounts: [],
        paidByOurAccounts: [],
        addedBy: "",
        modifiedBy: "",
        screenshot: "",
        createdAt: new Date()
    })

    useEffect(() => {
        if (selectedPayment && isEditMode) {
            setFormData({
                employeeId: selectedPayment?.employee._id,
                type: selectedPayment?.type,
                breakdownAmounts: selectedPayment?.breakdownAmounts.map((item) => ({
                    category: item.category,
                    amount: item.amount,
                    bonus: item.bonus,
                    bankAccount: item.bankAccount,
                    department: item.department
                })),
                paidByOurAccounts: selectedPayment?.paidByOurAccounts,
                createdAt: new Date(selectedPayment?.createdAt),
                addedBy: selectedPayment?.addedBy,
                modifiedBy: selectedPayment?.modifiedBy,
                screenshot: selectedPayment?.screenshot
            });
        }
    }, [selectedPayment, isEditMode]);


    const handleSubmit = async () => {
        try {
            //iterate through breakdown, if any breakdown bonus is empty then make it 0
            formData.breakdownAmounts.forEach((item) => {
                if (!item.bonus || item.bonus === 0 || item.bonus < 0 || isNaN(Number(item.bonus)) || item.bonus === undefined) {
                    item.bonus = 0;
                }
            });
            //if no screenshot is uploaded then make it empty
            if (!formData.screenshot && selectedPayment?.screenshot && selectedPayment?.screenshot !== "" && isEditMode) {
                formData.screenshot = selectedPayment?.screenshot;
            }

            //validate form data
            if (formData.breakdownAmounts.length === 0) {
                toast.error("Please add at least one breakdown amount");
                return;
            }
            //make sure all breakdown amounts are numbers and not negative
            formData.breakdownAmounts.forEach((item) => {
                if (isNaN(Number(item.amount)) || isNaN(Number(item.bonus)) || Number(item.amount) < 0 || Number(item.bonus) < 0) {
                    toast.error("Please enter valid numbers for breakdown amounts");
                    return;
                }
            });
            //iterate through breakdown amounts and make sure every field is filled
            formData.breakdownAmounts.forEach((item) => {
                if (!item.category || !item.amount || !item.bankAccount || !item.department) {
                    toast.error("Please fill all fields for breakdown amounts");
                    return;
                }
            });
            //make sure employeeId and bankAccount are not empty
            if (!formData.employeeId || !formData.type) {
                toast.error("Please select an employee and a payment type");
                return;
            }
            //make sure total amount is not negative
            if (formData.breakdownAmounts.reduce((sum, item) => sum + (Number(item.amount) + Number(item.bonus)), 0) < 0) {
                toast.error("Total amount cannot be negative");
                return;
            }
            //make sure total amount is not zero
            if (formData.breakdownAmounts.reduce((sum, item) => sum + (Number(item.amount) + Number(item.bonus)), 0) === 0) {
                toast.error("Total amount cannot be zero");
                return;
            }
            //make sure user is logged in
            if (!user?.username) {
                toast.error("Please login to continue");
                return;
            }
            //make sure createdAt is not in the future
            if (formData.createdAt > new Date()) {
                toast.error("Created at cannot be in the future");
                return;
            }
            //make sure paidByOurAccounts is not empty
            if (formData.paidByOurAccounts.length === 0) {
                toast.error("Please select at least one paid by our account");
                return;
            }
            //make sure screenshot is not empty
            if (!formData.screenshot) {
                toast.error("Please upload a screenshot");
                return;
            }

            // Check if in edit mode and no new screenshot is uploaded
            if (isEditMode && !formData.screenshot) {
                formData.screenshot = selectedPayment?.screenshot; // Retain the existing screenshot
            }

            const payload = {
                ...formData,
                totalAmount: formData.breakdownAmounts.reduce((sum, item) =>
                    sum + (Number(item.amount) + Number(item.bonus)), 0),
                addedBy: isEditMode ? selectedPayment?.addedBy : user?.username,
                modifiedBy: isEditMode ? selectedPayment?.modifiedBy : user?.username
            };

            const res = await apiHandler({
                data: payload,
                name: isEditMode ? "updateInternalPayment" : "createInternalPayment",
                params: isEditMode ? [`${selectedPayment?._id}`] : []
            });

            if (res.data?.isSuccess) {
                toast.success("Payment submitted successfully");

                setShowModel(false);
                setModalType("add");
                // Reset form   
                setFormData({
                    employeeId: "",
                    type: "",
                    breakdownAmounts: [],
                    paidByOurAccounts: [],
                    addedBy: "",
                    modifiedBy: "",
                    screenshot: "",
                    createdAt: new Date()
                });
                fetchInternalPayments();
            }
        } catch (error) {
            console.error("Payment submission error:", error);
            toast.error("Payment submission error");
        }
    };

    const convertToBase64 = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-4xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            {isEditMode ? "Update" : "Add New"} Payment
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setFormData({
                                    employeeId: "",
                                    type: "",
                                    breakdownAmounts: [],
                                    paidByOurAccounts: [],
                                    addedBy: "",
                                    modifiedBy: "",
                                    createdAt: new Date(),
                                    screenshot: ""
                                })
                                setShowModel(false);
                                setModalType("add");
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/*SELECT EMPLOYEE*/}
                    <div className='flex flex-col gap-2'>
                        <div className='flex justify-between gap-2'>
                            <div className='flex flex-col gap-2 w-full'>
                                <label htmlFor="employeeId">Employee</label>
                                <Select
                                    disabled={isEditMode}
                                    showSearch
                                    placeholder="Select an employee"
                                    options={employees.map((employee) => ({ label: employee.name, value: employee._id }))}
                                    onChange={(value) => {
                                        setFormData({ ...formData, employeeId: value });
                                    }}
                                    className="w-full h-10 border border-gray-300 font-rubik"
                                    bordered={false}
                                    value={formData.employeeId}
                                />
                            </div>
                        </div>

                        <label htmlFor="type">Payment Type</label>
                        <Select
                            placeholder="Select payment type"
                            options={[
                                { label: 'Salary', value: 'SALARY' },
                                { label: 'Per Task', value: 'PER_TASK' },
                                { label: 'Advance', value: 'ADVANCE' },
                                { label: 'Bonus', value: 'BONUS' },
                                { label: 'Commission', value: 'COMMISSION' },
                                { label: 'Other', value: 'OTHER' },

                            ]}
                            onChange={(value) => setFormData({ ...formData, type: value })}
                            className="w-full h-10 border border-gray-300 font-rubik"
                            bordered={false}
                            value={formData.type}
                        />

                        {/* Breakdown Amounts Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Payment Breakdown</label>
                            {formData.breakdownAmounts.map((item, index) => (
                                <div key={index} className="grid grid-cols-2 gap-2 relative mb-6 bg-gray-50 p-4 rounded-lg">
                                    <CustomDropDown
                                        options={categoryValues.map((category) => category.value)}
                                        onSelect={(value) => {
                                            const newBreakdown = [...formData.breakdownAmounts];
                                            newBreakdown[index].category = value;
                                            setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                        }}
                                        value={item.category}
                                        label="Category"
                                        width="w-full"
                                    />
                                    <div className='flex flex-col gap-2'>
                                        <CustomDropDown
                                            options={employees.find((employee) => employee._id === formData.employeeId)?.bankDetails.map((bankAccount) =>
                                                `${bankAccount?.accountHolderName} | ${bankAccount?.accountNumber} | ${bankAccount?.bankName}`
                                            ) || []}
                                            onSelect={(value) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].bankAccount = value;
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                            value={item.bankAccount}
                                            label="Bank Account"
                                            width="w-full"
                                        />
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="amount">Amount</label>
                                        <input
                                            type="number"
                                            placeholder="Amount"
                                            className="border p-2 rounded w-full bg-transparent"
                                            value={item.amount || ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].amount = e.target.value === '' ? 0 : Number(e.target.value);
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="bonus">Bonus</label>
                                        <input
                                            type="number"
                                            placeholder="Bonus"
                                            className="border p-2 rounded w-full bg-transparent"
                                            value={item.bonus !== undefined ? item.bonus : ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].bonus = e.target.value === '' ? 0 : Number(e.target.value);
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <CustomDropDown
                                            options={departments.map((department) => department.name)}
                                            onSelect={(value) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].department = value;
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                            label="Department"
                                            width="w-full"
                                            value={item.department}                                            
                                        />
                                    </div>

                                    <X onClick={() => {
                                        const newBreakdown = formData.breakdownAmounts.filter((_, i) => i !== index);
                                        setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                    }}
                                        className="bg-red-500 text-white px-2 rounded absolute top-0 right-0"
                                    />
                                </div>
                            ))}
                            <ButtonFill
                                handleClick={() => {
                                    setFormData({
                                        ...formData,
                                        breakdownAmounts: [...formData.breakdownAmounts, {
                                            category: '',
                                            amount: 0,
                                            bonus: 0,
                                            bankAccount: '',
                                            department: ''
                                        }]
                                    });
                                }}
                            >
                                Add Breakdown
                            </ButtonFill>
                        </div>

                        {/* Paid By Our Accounts Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Paid By Our Accounts</label>
                            <Select
                                placeholder="Select paid by our accounts"
                                options={bankAccounts.map((bankAccount) => ({
                                    label: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                    value: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                }))}
                                onChange={(value) => {
                                    setFormData({ ...formData, paidByOurAccounts: value });
                                }}
                                className="w-full h-10 border border-gray-300 font-rubik"
                                bordered={false}
                                value={formData.paidByOurAccounts}
                                mode='multiple'
                            />
                        </div>

                        {/* Added By Section */}

                        {/* Created At Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Payed At</label>
                            <input type="date" placeholder="Created At" className="border p-2 rounded" value={formData.createdAt.toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, createdAt: new Date(e.target.value) })} />
                        </div>

                        {/* Screenshot Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Screenshot</label>
                            <input type="file" className="border p-2 rounded"
                                accept="image/png, image/jpeg"
                                onChange={async (e) => {
                                    //upload in base64  
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        const base64 = await convertToBase64(file);
                                        setFormData({ ...formData, screenshot: base64 as string });
                                    }
                                }} />
                            {formData.screenshot && <img src={formData.screenshot} alt="Screenshot" className="w-24 h-24 object-fit border mt-2 rounded" />}
                        </div>

                        {/* Submit Button */}
                        <div className="mt-4 flex justify-end">
                            <ButtonFill
                                handleClick={handleSubmit}
                                disabled={!formData.employeeId || !formData.type}
                            >
                                {isEditMode ? 'Update' : 'Submit'} Payment
                            </ButtonFill>
                        </div>
                        <Toaster />
                    </div>

                </div>
            </div>
        </div>
    )
}


export const ViewPaymentModal = ({ ShowModel, setShowModel, selectedPayment }: { ShowModel: boolean, setShowModel: (value: boolean) => void, selectedPayment: any }) => {

    const GetDate = (date: Date) => {
        return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    return (
        <div
            id="viewPaymentModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-6xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            View Payment
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setShowModel(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* TODO: Add Payment Details */}
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-row gap-2'>
                            <b>Employee:</b> {selectedPayment?.employee?.name}
                        </div>
                        <div className='flex flex-row gap-2'>
                            <b>Type:</b> {selectedPayment?.type}
                        </div>
                        <div className='flex flex-row gap-2'>
                            <b>Amount Paid:</b> {selectedPayment?.totalAmount}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <b>Paid By Our Accounts:</b>
                            <div className='flex flex-row gap-2'>
                                {selectedPayment?.paidByOurAccounts.map((account: any, index: number) =>
                                    <div key={index}>
                                        <b>{index + 1}.</b> {account}
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div className='flex flex-col gap-2'>
                            <b>Breakdown Amounts:</b>
                            <div className='flex flex-row gap-2 w-full'>
                                <table className='w-full'>
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-4 py-4">
                                                No.
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Category
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Amount
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Bonus
                                            </th>
                                            <th scope="col" className="px-4 py-4">  
                                                Department
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Bank Account
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedPayment?.breakdownAmounts.map((item: any, index: number) =>
                                            <tr key={index} className="border-b">
                                                <td className="px-4 py-2 text-center">{index + 1}</td>
                                                <td className="px-4 py-2 text-center">{item?.category}</td>
                                                <td className="px-4 py-2 text-center">{item?.amount}</td>
                                                <td className="px-4 py-2 text-center">{item?.bonus}</td>
                                                <td className="px-4 py-2 text-center">{item?.department}</td>
                                                <td className="px-4 py-2 text-center">
                                                    <table className='w-full flex justify-center flex-col items-center'>
                                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="px-4 py-2 text-xs">
                                                                    Holder Name
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs">
                                                                    Account Number
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs">
                                                                    Bank Name
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="w-full">
                                                                <td className="px-4 py-3 text-center text-sm">{item?.bankAccount.split(' | ')[0]}</td>
                                                                <td className="px-4 py-3 text-center text-sm">{item?.bankAccount.split(' | ')[1]}</td>
                                                                <td className="px-4 py-3 text-center text-sm">{item?.bankAccount.split(' | ')[2]}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='flex flex-row gap-2 justify-between'>
                            <div className='flex justify-start gap-2'><b>Added By:</b> {selectedPayment?.addedBy}</div>
                            <div className='flex justify-end gap-2'><b>Created At:</b> {GetDate(selectedPayment?.createdAt)}</div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    )
}

export const InternalPaymentsTable = (
    {
        internalPayments,
        setOpenAddPaymentModal,
        setModalType,
        setSelectedPayment,
        setOpenViewPaymentModal,
        showEdit = true,
        GetDate,
        fetchInternalPayments
    }: {
        internalPayments: any[],
        setOpenAddPaymentModal: (value: boolean) => void,
        setModalType: (value: string) => void,
        setSelectedPayment: (value: any) => void,
        setOpenViewPaymentModal: (value: boolean) => void,
        GetDate: (date: string) => string,
        fetchInternalPayments: () => void,
        showEdit?: boolean
    }) => {

    const DeleteInternalPayment = async (id: string) => {
        try {
            const response = await apiHandler({
                name: "deleteInternalPayment",
                params: [id]
            })
            if (response.data.isSuccess) {
                fetchInternalPayments();
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to delete internal payment");
            console.error(error);
        }
    }


    return (
        <div className="overflow-x-auto">
            <table className="w-full mb-20 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th scope="col" className="px-4 py-4">
                            Date of Payment
                        </th>
                        <th scope="col" className="px-4 py-4">
                            Employee Name
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                            Type
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                            Amount Paid
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                            Screenshot
                        </th>
                        <th scope="col" className="px-4 py-3 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {internalPayments.map((payment) => (
                        <tr className="border-b" key={payment?._id}>
                            <td className="px-4 py-3">{GetDate(payment?.createdAt)}</td>
                            <td className="px-4 py-3">{payment?.employee?.name}</td>
                            <td className="px-4 py-3">{payment?.type}</td>
                            <td className="px-4 py-3">{payment?.totalAmount}</td>
                            <td className="px-4 py-3">
                                <div className='text-blue-500 cursor-pointer underline'
                                    onClick={() => {
                                        window.open(payment?.screenshot, '_blank');
                                    }}
                                >
                                    Screenshot Link
                                </div>
                            </td>
                            <td className="px-4 py-3 text-center flex items-center justify-center">
                                <div className="cursor-pointer flex items-center gap-4">
                                    {showEdit && <div className='cursor-pointer'
                                        onClick={() => {
                                            setOpenAddPaymentModal(true);
                                            setModalType("edit");
                                            setSelectedPayment(payment);
                                        }}
                                    >
                                        <EditIcon
                                            size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                        />
                                    </div>}
                                    <div className='cursor-pointer'
                                        onClick={() => {
                                            setOpenViewPaymentModal(true);
                                            setSelectedPayment(payment);
                                        }}
                                    >

                                        <EyeIcon
                                            size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                        />
                                    </div>
                                    <div className='cursor-pointer'
                                        onClick={() => {
                                            DeleteInternalPayment(payment?._id);
                                        }}
                                    >
                                        <DeleteIcon
                                            size='w-6 h-6 hover:text-red-500 hover:scale-110 transition-all duration-300'
                                        />
                                    </div>
                                </div>


                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
