import { apiHandler, apiRequest } from '(apis)/api-interface/api-handler';
import React, { useContext, useEffect, useState } from 'react'
import { Select } from 'antd';
import CustomDropDown from 'shared-components/ui/CustomDropDown';
import { categoryValues } from 'constants/constantvalues';
import { X } from '@mui/icons-material';
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import toast, { Toaster } from 'react-hot-toast';
import { UserContext } from 'state-management/user-context';
import { EditIcon, EyeIcon, DeleteIcon } from 'shared-components/ui/Icons';
import { Input } from 'shared-components/ui/CustomForm';

export const MiscPaymentModal = ({ ShowModel, setShowModel, isEditMode, selectedMiscPayment, fetchMiscPayments }: { ShowModel: boolean, setShowModel: (value: boolean) => void, isEditMode: boolean, selectedMiscPayment: any, fetchMiscPayments: () => void }) => {

    const [bankAccounts, setBankAccounts] = useState([]);
    const { user } = useContext(UserContext);

    const GetServiceProviders = async () => {
        const res2 = await apiRequest("getBankAccounts");
        if (res2.data?.isSuccess) {
            setBankAccounts(res2.data?.bankAccounts);
        }
    }

    useEffect(() => {
        GetServiceProviders();
    }, [])


    interface BreakDownAmounts {
        category: string;
        amount: number;
    }

    const [formData, setFormData] = useState<{
        expenseName: string;
        breakdownAmounts: BreakDownAmounts[];
        paidByOurAccounts: string[];
        addedBy: string;
        modifiedBy: string;
        screenshot: string;
        createdAt: Date;
        description: string;
    }>({
        expenseName: "",
        breakdownAmounts: [],
        paidByOurAccounts: [],
        addedBy: "",
        modifiedBy: "",
        screenshot: "",
        createdAt: new Date(),
        description: ""
    })

    useEffect(() => {
        if (selectedMiscPayment && isEditMode) {
            setFormData({
                expenseName: selectedMiscPayment?.expenseName,
                breakdownAmounts: selectedMiscPayment?.breakdownAmounts.map((item: any) => ({
                    category: item.category,
                    amount: item.amount,
                })),
                paidByOurAccounts: selectedMiscPayment?.paidByOurAccounts,
                createdAt: new Date(selectedMiscPayment?.createdAt),
                addedBy: selectedMiscPayment?.addedBy,
                modifiedBy: selectedMiscPayment?.modifiedBy,
                screenshot: selectedMiscPayment?.screenshot,
                description: selectedMiscPayment?.description
            });
        }
    }, [selectedMiscPayment, isEditMode]);


    const handleSubmit = async () => {
        try {
            //validate form data
            if (formData.breakdownAmounts.length === 0) {
                toast.error("Please add at least one breakdown amount");
                return;
            }
            //make sure all breakdown amounts are numbers and not negative
            formData.breakdownAmounts.forEach((item) => {
                if (isNaN(Number(item.amount)) || Number(item.amount) < 0) {
                    toast.error("Please enter valid numbers for breakdown amounts");
                    return;
                }
            });
            //iterate through breakdown amounts and make sure every field is filled
            formData.breakdownAmounts.forEach((item) => {
                if (!item.category || !item.amount) {
                    toast.error("Please fill all fields for breakdown amounts");
                    return;
                }
            });
            //make sure expenseName is not empty
            if (!formData.expenseName) {
                toast.error("Please enter an expense name");
                return;
            }
            //make sure total amount is not negative
            if (formData.breakdownAmounts.reduce((sum, item) => sum + Number(item.amount), 0) < 0) {
                toast.error("Total amount cannot be negative");
                return;
            }
            //make sure total amount is not zero
            if (formData.breakdownAmounts.reduce((sum, item) => sum + Number(item.amount), 0) === 0) {
                toast.error("Total amount cannot be zero");
                return;
            }
            //make sure user is logged in
            if (!user?.username) {
                toast.error("Please login to continue");
                return;
            }
            //make sure createdAt is not in the future
            if (formData.createdAt > new Date()) {
                toast.error("Created at cannot be in the future");
                return;
            }
            //make sure paidByOurAccounts is not empty
            if (formData.paidByOurAccounts.length === 0) {
                toast.error("Please select at least one paid by our account");
                return;
            }
            //make sure screenshot is not empty
            if (!formData.screenshot) {
                toast.error("Please upload a screenshot");
                return;
            }

            const payload = {
                ...formData,
                totalAmount: formData.breakdownAmounts.reduce((sum, item) =>
                    sum + Number(item.amount), 0),
                addedBy: isEditMode ? selectedMiscPayment?.addedBy : user?.username,
                modifiedBy: isEditMode ? selectedMiscPayment?.modifiedBy : user?.username
            };

            const res = await apiHandler({
                data: payload,
                name: isEditMode ? "updateMiscellaneousPayment" : "addMiscellaneousPayment",
                params: isEditMode ? [`${selectedMiscPayment?._id}`] : []
            });

            if (res.data?.isSuccess) {
                toast.success("Payment submitted successfully");

                setShowModel(false);
                // Reset form   
                setFormData({
                    expenseName: "",
                    breakdownAmounts: [],
                    paidByOurAccounts: [],
                    addedBy: "",
                    modifiedBy: "",
                    screenshot: "",
                    createdAt: new Date(),
                    description: ""
                });
                fetchMiscPayments();
            }
        } catch (error) {
            console.error("Payment submission error:", error);
            toast.error("Payment submission error");
        }
    };

    const convertToBase64 = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-4xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            {isEditMode ? "Update" : "Add New"} Payment
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setFormData({
                                    expenseName: "",
                                    breakdownAmounts: [],
                                    paidByOurAccounts: [],
                                    addedBy: "",
                                    modifiedBy: "",
                                    createdAt: new Date(),
                                    screenshot: "",
                                    description: ""
                                })
                                setShowModel(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/*SELECT EMPLOYEE*/}
                    <div className='flex flex-col gap-2'>
                        <div className='flex justify-between gap-2'>
                            <div className='flex flex-col gap-2 w-full'>
                                <label htmlFor="expenseName">Expense Name</label>
                                <Input
                                    name="expenseName"
                                    value={formData.expenseName}
                                    onChange={(name, value) => setFormData({ ...formData, [name]: value })}
                                />
                            </div>
                        </div>

                   
                        {/* Breakdown Amounts Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Payment Breakdown</label>
                            {formData.breakdownAmounts.map((item, index) => (
                                <div key={index} className="grid grid-cols-2 gap-2 relative mb-6 bg-gray-50 p-4 rounded-lg">
                                    <CustomDropDown
                                        options={categoryValues.map((category) => category.value)}
                                        onSelect={(value) => {
                                            const newBreakdown = [...formData.breakdownAmounts];
                                            newBreakdown[index].category = value;
                                            setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                        }}
                                        value={item.category}
                                        label="Category"
                                        width="w-full"
                                    />
                                   
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="amount">Amount</label>
                                        <input
                                            type="number"
                                            placeholder="Amount"
                                            className="border p-2 rounded w-full bg-transparent"
                                            value={item.amount || ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].amount = e.target.value === '' ? 0 : Number(e.target.value);
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>


                                    <X onClick={() => {
                                        const newBreakdown = formData.breakdownAmounts.filter((_, i) => i !== index);
                                        setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                    }}
                                        className="bg-red-500 text-white px-2 rounded absolute top-0 right-0"
                                    />
                                </div>
                            ))}
                            <ButtonFill
                                handleClick={() => {
                                    setFormData({
                                        ...formData,
                                        breakdownAmounts: [...formData.breakdownAmounts, {
                                            category: '',
                                            amount: 0,                                          
                                        }]
                                    });
                                }}
                            >
                                Add Breakdown
                            </ButtonFill>
                        </div>

                        {/* Paid By Our Accounts Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Paid By Our Accounts</label>
                            <Select
                                placeholder="Select paid by our accounts"
                                options={bankAccounts.map((bankAccount) => ({
                                    label: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                    value: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                }))}
                                onChange={(value) => {
                                    setFormData({ ...formData, paidByOurAccounts: value });
                                }}
                                className="w-full h-10 border border-gray-300 font-rubik"
                                bordered={false}
                                value={formData.paidByOurAccounts}
                                mode='multiple'
                            />
                        </div>

                        {/* Added By Section */}

                        {/* Created At Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Payed At</label>
                            <input type="date" placeholder="Created At" className="border p-2 rounded" value={formData.createdAt.toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, createdAt: new Date(e.target.value) })} />
                        </div>

                        {/* Screenshot Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Screenshot</label>
                            <input type="file" className="border p-2 rounded"
                                accept="image/png, image/jpeg"
                                onChange={async (e) => {
                                    //upload in base64  
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        const base64 = await convertToBase64(file);
                                        setFormData({ ...formData, screenshot: base64 as string });
                                    }
                                }} />
                            {formData.screenshot && <img src={formData.screenshot} alt="Screenshot" className="w-24 h-24 object-fit border mt-2 rounded" />}
                        </div>

                        {/* Description Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Description</label>
                            <Input
                                value={formData.description}
                                onChange={(name, value) => setFormData({ ...formData, [name]: value })}
                                name="description"
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="mt-4 flex justify-end">
                            <ButtonFill
                                handleClick={handleSubmit}
                                disabled={!formData.expenseName}
                            >
                                {isEditMode ? 'Update' : 'Submit'} Payment
                            </ButtonFill>
                        </div>
                        <Toaster />
                    </div>

                </div>
            </div>
        </div>
    )
}


export const ViewMiscPaymentModal = ({ ShowModel, setShowModel, selectedMiscPayment }: { ShowModel: boolean, setShowModel: (value: boolean) => void, selectedMiscPayment: any }) => {

    const GetDate = (date: Date) => {
        return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    return (
        <div
            id="viewPaymentModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-6xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            View Payment
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setShowModel(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* TODO: Add Payment Details */}
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-row gap-2'>
                            <b>Expense Name:</b> {selectedMiscPayment?.expenseName}
                        </div>
                        <div className='flex flex-row gap-2'>
                            <b>Amount Paid:</b> {selectedMiscPayment?.totalAmount}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <b>Paid By Our Accounts:</b>
                            <div className='flex flex-row gap-2'>
                                {selectedMiscPayment?.paidByOurAccounts.map((account: any, index: number) =>
                                    <div key={index}>
                                        <b>{index + 1}.</b> {account}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <b>Breakdown Amounts:</b>
                            <div className='flex flex-row gap-2 w-full'>
                                <table className='w-full'>
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-4 py-4">
                                                No.
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Category
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedMiscPayment?.breakdownAmounts.map((item: any, index: number) =>
                                            <tr key={index} className="border-b">
                                                <td className="px-4 py-2 text-center">{index + 1}</td>
                                                <td className="px-4 py-2 text-center">{item?.category}</td>
                                                <td className="px-4 py-2 text-center">{item?.amount}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='flex flex-row gap-2 justify-between'>
                            <div className='flex justify-start gap-2'><b>Added By:</b> {selectedMiscPayment?.addedBy}</div>
                            <div className='flex justify-end gap-2'><b>Created At:</b> {GetDate(selectedMiscPayment?.createdAt)}</div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    )
}

export const MiscPaymentsTable = (
    {
        miscPayments,
        setOpenAddMiscPaymentModal,
        setModalType,
        setSelectedMiscPayment,
        setOpenViewMiscPaymentModal,
        GetDate,
        fetchMiscPayments
    }: {
        miscPayments: any[],
        setOpenAddMiscPaymentModal: (value: boolean) => void,
        setModalType: (value: string) => void,
        setSelectedMiscPayment: (value: any) => void,
        setOpenViewMiscPaymentModal: (value: boolean) => void,
        GetDate: (date: string) => string,
        fetchMiscPayments: () => void
    }) => {

    const DeleteMiscPayment = async (id: string) => {
        try {
            const response = await apiHandler({
                name: "deleteMiscPayment",
                params: [id]
            })
            if (response.data.isSuccess) {
                fetchMiscPayments();
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to delete miscellaneous payment");
            console.error(error);
        }
    }


    return (
        <div className="overflow-x-auto">
            <table className="w-full mb-20 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th scope="col" className="px-4 py-4">
                            Date of Payment
                        </th>
                        <th scope="col" className="px-4 py-4">
                            Expense Name
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                            Amount Paid
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                            Screenshot
                        </th>
                        <th scope="col" className="px-4 py-3 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {miscPayments.map((payment) => (
                        <tr className="border-b" key={payment?._id}>
                            <td className="px-4 py-3">{GetDate(payment?.createdAt)}</td>
                            <td className="px-4 py-3">{payment?.expenseName}</td>
                            <td className="px-4 py-3">{payment?.totalAmount}</td>
                            <td className="px-4 py-3">
                                <div className='text-blue-500 cursor-pointer underline'
                                    onClick={() => {
                                        window.open(payment?.screenshot, '_blank');
                                    }}
                                >
                                    Screenshot Link
                                </div>
                            </td>
                            <td className="px-4 py-3 text-center flex items-center justify-center">
                                <div className="cursor-pointer flex items-center gap-4">
                                    <div className='cursor-pointer'
                                        onClick={() => {
                                            setOpenAddMiscPaymentModal(true);
                                            setModalType("edit");
                                            setSelectedMiscPayment(payment);
                                        }}
                                    >
                                        <EditIcon
                                            size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                        />
                                    </div>
                                    <div className='cursor-pointer'
                                        onClick={() => {
                                            setOpenViewMiscPaymentModal(true);
                                            setSelectedMiscPayment(payment);
                                        }}
                                    >

                                        <EyeIcon
                                            size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                        />
                                    </div>
                                    <div className='cursor-pointer'
                                        onClick={() => {
                                            DeleteMiscPayment(payment?._id);
                                        }}
                                    >
                                        <DeleteIcon
                                            size='w-6 h-6 hover:text-red-500 hover:scale-110 transition-all duration-300'
                                        />
                                    </div>

                                </div>


                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
